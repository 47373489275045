//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductData from "@/shared/json/product-data.json";
import ProductDataAr from "@/shared/json/product-data-ar.json";
import i18n from "@/vueI18n";
import metalCard from "./metalCard.vue";

export default {
  name: "Products",
  data() {
    return {
      //    items: ProjectsData.projectsData
    };
  },
  components: {
    metalCard,
  },
  computed: {
    language() {
      return i18n.locale;
    },
    items() {
      if (this.language == "en") return ProductData.projectsData;
      return ProductDataAr.projectsData;
    },
  },
};
