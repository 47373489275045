//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;

export default {
  name: "PageTitle",
  methods: {
    handleScroll(eleId) {
      const ele = document.getElementById(eleId); // You need to get your element here
      const target = getScrollTarget(ele);
      // const header = document.getElementById("header");
      const offset = ele.offsetTop + 150;
      const duration = 500;
      setScrollPosition(target, offset, duration);
    },
  },
};
