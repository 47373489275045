//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from "@/vueI18n";
import PageTitle from "@/modules/home/components/page-title.vue";
import AboutUs from "@/modules/home/components/about-us.vue";
import Products from "@/modules/home/components/product.vue";
import Contacts from "@/modules/home/components/contacts.vue";

export default {
  components: {
    PageTitle,
    AboutUs,
    Products,
    Contacts,
  },
  mounted: function () {
    document.body.classList.add("home");
    document.body.classList.add("bg-fixed");
    document.body.classList.add("bg-stripes");
  },
  beforeDestroy() {
    document.body.classList.remove("home");
    document.body.classList.remove("bg-fixed");
    document.body.classList.remove("bg-stripes");
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  watch: {
    language() {
      document.title = this.i18n("app.title") + " | " + this.i18n("app.home");
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
