//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["title", "desc", "hue"],
};
