//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VuePhoneNumberInput from "vue-phone-number-input";
import i18n from "@/vueI18n";
import { mapActions } from "vuex";

export default {
  name: "ContactsForm",
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
      },
      successMessage: "Sender's message was sent successfully",
      warningMessage: "Fill up the form, please!",
      errorMessage: "Something go wrong. Try again later!",
      responseMessage: "",
      alertClass: "",
      callAlert: false,
      phone: null,
      TRansObject: {
        countrySelectorLabel: "Country Code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone Number",
        example: "Example :",
      },
      TRansObjectAr: {
        countrySelectorLabel: "كود البلد",
        countrySelectorError: "إختر بلد",
        phoneNumberLabel: "رقم الهاتف",
        example: "مثال :",
      },
    };
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      doSubmitContactForm: "home/doSubmitContactForm",
    }),
    phoneNumberInput(data) {
      this.phone = data;
    },
    doValidate() {
      if (
        this.isValidEmail() &&
        this.form.phoneNumber != "" &&
        this.form.message != "" &&
        this.form.name != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    async submitForm() {
      if (this.doValidate()) {
        this.showNotif("success");
        this.doSubmitContactForm({
          name: this.form.name,
          phoneNumber: this.phone.formattedNumber,
          email: this.form.email,
          message: this.form.message,
          language: this.language,
        });
        this.form.name = "";
        this.form.email = "";
        this.form.phoneNumber = "";
        this.form.message = "";
      } else {
        this.showNotif("fail");
      }
    },
    showNotif(value) {
      if (value == "success") {
        this.$notify({
          group: "notification",
          title: this.i18n("contactUs.form.formSubmittedTitle"),
          text: this.i18n("contactUs.form.formSubmittedSubtitle"),
          type: "success",
          position: "top right",
        });
      } else {
        this.$notify({
          group: "notification",
          title: this.i18n("contactUs.form.formErrorTitle"),
          text: this.i18n("contactUs.form.formErrorSubtitle"),
          type: "error",
          position: "top right",
        });
      }
    },
    isValidEmail() {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      //
      if (emailPattern.test(this.form.email)) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
};
